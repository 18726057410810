.contact-form-component {
    display: flex;
    @media (max-width: $break-medium) {
        flex-direction: column;
    }
    gap: 1.2rem;
    .form-wrapper, .contact-wrapper {
        flex: 1;
        border-radius: 2rem;
        background-color: $gray-transparent;
        backdrop-filter: blur(10px) saturate(180%);
        box-shadow: 0px 0px 2px $white-transparent;
        padding: 2.4rem;
        .form-title {
            text-transform: uppercase;
            font-size: 2.4rem;
            font-weight: 500;
            text-align: center;
            margin-bottom: 2.4rem;
            color: $white;
            &.with-space {
                margin-top: 4rem;
            }
        }
        .contacts-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1.2rem;
            .contact-line {
                color: $white;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1.2rem;
                i {
                    font-size: 4.2rem;
                }
            }
        }
        .feedback-message {
            color: $white;
            span {
                color: $primary-color;
                font-weight: 500;
            }
        }
    }
    .form-wrapper {
        form.contact-form {
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
            .form-input {
                position: relative;
                display: flex;
                width: 100%;
                padding-top: 2.2rem;
                transition: all .2s ease-in-out;
                &:has(.error) {
                    padding-bottom: 1.8rem;
                }
                label {
                    position: absolute;
                    top: 0;
                    left: .5rem;
                    font-size: 1.2rem;
                    color: $white;
                }
                input, select, textarea {
                    width: 100%;
                    background-color: transparent;
                    outline: none;
                    border: 0;
                    color: $white;
                    padding: .8rem 1.2rem;
                    backdrop-filter: blur(10px) saturate(180%);
                    box-shadow: 0px 0px 2px $white-transparent;
                    border-radius: 1.5rem;
                }
                textarea {
                    min-height: 8rem;
                    max-width: 100%;
                }
                .error {
                    position: absolute;
                    color: rgb(255, 144, 144);
                    bottom: 0;
                    left: .5rem;
                    font-size: 1rem;
                    text-shadow: 0 0 3px $black;
                }
            }
            .buttons {
                display: flex;
                justify-content: flex-end;
                margin-top: 1.2rem;
                gap: 2.4rem;

                @media (max-width: $break-medium) {
                    flex-direction: column-reverse;
                    .cta {
                        width: 100% !important;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .cta {
                    border: 1px solid $white;
                    border-radius: 3rem;
                    padding: .4rem 1.8rem;
                    font-weight: 300;
                    width: fit-content;


                    button {
                        text-transform: uppercase;
                        color: $white;
                    }
                    i {
                        color: $white;
                    }
                }
            }
        }
    }
    &.general {
        display: flex;
        flex-direction: column;
        .form-title {
            text-transform: uppercase;
            font-size: 2.4rem;
            font-weight: 500;
            text-align: center;
            margin-bottom: 2.4rem;
            color: $white;
        }
        .brand-choose {
            display: flex;
            width: 100%;
            gap: 1rem;
            @media (max-width: $break-medium) {
                flex-direction: column;
            }
            button {
                flex: 1;
                border-radius: 2rem;
                background-color: $gray-transparent;
                backdrop-filter: blur(10px) saturate(180%);
                box-shadow: 0px 0px 2px $white-transparent;
                padding: 2.4rem;
                cursor: pointer;

                img {
                    height: 9rem;
                    width: auto;
                }
            }
        }
    }
}